.footer-heading {
    font-family: Unifont;
    font-size: 1.4rem;
    font-weight: bold;
    text-align: left;
    color: #fff;
    margin-bottom: 25px;
    max-width: 200px;
}
.footerWethio li{
    list-style-type: none;
    margin: 10px 0px;
}
.copy-right{
    color: #fff;
}


.wethio-Footer{
    /* position: absolute; */
    bottom: 0;
    width: 100%;
    display: block;
}
.footerWethio {
padding-left: 0px;
}
.footerWethio a{
    text-decoration: none;
    font-family: 'Kanit', sans-serif;
    font-size: 16px;
    font-weight: inherit;
    text-align: left;
    color: #fff !important;

}
.wethio-Footer{
    background-color: #080b35;
}


.searchfield{
    margin-right: 20px;
}
.logo-text{
    font-size: 35px;
}


.theme-color{
    background-color: #080b35;
}