.cls-10,
.cls-2,
.cls-3,
.cls-4,
.cls-5,
.cls-6,
.cls-7 {
  strokelinecap: round;
  strokelinejoin: round;
}
.cls-2 {
  fill: #e4761b;
  stroke: #e4761b;
}
.cls-3 {
  fill: #d7c1b3;
  stroke: #d7c1b3;
}
.cls-4 {
  fill: #233447;
  stroke: #233447;
}
.cls-5 {
  fill: #cd6116;
  stroke: #cd6116;
}
.cls-6 {
  fill: #e4751f;
  stroke: #e4751f;
}
.cls-7 {
  fill: #f6851b;
  stroke: #f6851b;
}
.cls-10 {
  fill: #763d16;
  stroke: #763d16;
}
