:root {
  --primary-main: #edac75;
  --text-black: #0e0e1e;
  --text-primary: #fff;
  --text-secondary: #636880;
  --text-light: #b7b7b7;
  --error: #f44336;
  --warning: #ff9800;
  --success: #4caf50;
  --info: #2196f3;
  --error-light: #e57373;
  --warning-light: #ffb74d;
  --success-light: #81c784;
  --info-light: #64b5f6;
  --toast-bg: #212123;
}

@font-face {
  font-family: 'Kanit';
  src: local('Kanit'),
    url(./assets/fonts/Kanit/Kanit-Bold.ttf) format('ttf');
}

@font-face {
  font-family: UniKanit;
  src: url(./assets/fonts/Kanit/Kanit-ExtraBold.ttf)
    format('truetype');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Kanitlight';
  src: local('Kanitlight'),
    url(./assets/fonts/Kanit/Kanit-Regular.ttf) format('ttf');
}

body {
  margin: 0;
  font-family: 'Kanit', 'Montserrat', sans-serif, -apple-system,
    BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-primary);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 100%;
}

p {
  font-size: 16px;
  /* margin: 0; */
  padding: 0;
}

/* font-size helper classes */
.title1 {
  font-size: 24px;
}
.title2 {
  font-size: 28px;
}
.title3 {
  font-size: 32px;
}

.title4 {
  font-size: 36px;
}

.title5 {
  font-size: 42px;
}

.title6 {
  font-size: 52px;
}

.subTitle1 {
  font-size: 2px;
}
.subTitle2 {
  font-size: 22px;
}
.text1 {
  font-size: 16px;
}
.text2 {
  font-size: 18px;
}
.subText1 {
  font-size: 11px;
}
.subText2 {
  font-size: 12px;
}
.subText3 {
  font-size: 13px;
}
.subText4 {
  font-size: 14px;
}
.subText5 {
  font-size: 15px;
}

.subText6 {
  font-size: 16px;
}

/* color helper classes */

.text-weak {
  color: var(--text-light);
}
.text-black {
  color: var(--text-black);
}
.text-strong {
  color: var(--text-secondary);
}
.color-primary {
  color: var(--text-primary);
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.w-0 {
  width: 0 !important;
}

/* modal fix */
body {
  padding-right: 0 !important;
}
body.modal-open {
  overflow: hidden;
}

.font-unifont {
  font-family: Unifont;
}

/* removing carousel indicators globally */

.carousel-control-prev,
.carousel-control-next {
  display: none !important;
}

.carousel .carousel-indicators {
  bottom: -10%;
}
